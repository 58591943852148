<template>
  <div
    :id="String(product.id) + 'open'"
    class="openProductWrapper"
    :style="openProduct"
  >
    <div class="openProduct">
      <div class="header">
        <div class="autoSelect">
          <MainButton
            :clickFunction="clearAll"
            title="Clear all"
            styleClass="secondary"
            :buttonStyle="{
              'margin-bottom': '10px',
            }"
          />
        </div>
        <div class="errorMsg">
          <p>{{ errorMsg }}</p>
        </div>
      </div>
      <div
        v-for="(inventory, inventoryIndex) in product.inventories"
        :key="inventory.storage_location_id"
      >
        <fragment
          v-if="inventory.batches.length > 0 && includesUnit(inventory.batches)"
        >
          <div class="topInfo">
            <p>
              {{ inventory.name }}
              <span
                >Available in storage: ({{ getTotalAmount(inventory.batches) }}
                {{ product.unit }})</span
              >
            </p>
          </div>
          <table class="standardTable">
            <tr>
              <th>Batch</th>
              <th>Available</th>
              <th>Experation</th>
              <th>Amount</th>
              <th>Empty</th>
            </tr>
            <OpenProductBatch
              :calcPrice="calcPrice"
              :products="products"
              :orderInfo="orderInfo"
              :setErrorMessage="setErrorMessage"
              :inventoryIndex="inventoryIndex"
              :setSelectedElement="setSelectedElement"
              :product="product"
              :batch="batch"
              :index="index"
              v-for="(batch, index) in inventory.batches"
              :key="batch.batchNumber"
            />
          </table>
        </fragment>
      </div>
    </div>
  </div>
</template>

<script>
import OpenProductBatch from '@/components/pos/gridComponents/OpenProductBatch'
import MainButton from '@/layout/MainButton.vue'

export default {
  props: [
    'product',
    'displayOpenProduct',
    'setSelectedElement',
    'orderInfo',
    'products',
    'calcPrice'
  ],
  components: { OpenProductBatch, MainButton },
  data() {
    return {
      errorMsg: '',
      displayMsg: '',
    }
  },
  watch: {
    'product.amountChosen': function () {
      // KAn gp i loop
      this.clearAll()
    },
  },
  methods: {
    
    getTotalAmount(batches) {
      return batches.reduce((prevAmount, batch) => prevAmount + batch.amount, 0)
    },
    includesUnit(batches) {
      const found = batches.some((batch) => batch.unit == this.product.unit)
      return found
    },
    setErrorMessage(msg) {
      this.errorMsg = msg
      setTimeout(function () {
        this.errorMsg = ''
      }, 3000)
    },
    clearAll() {
      this.errorMsg = ''
      this.product.inventories.forEach((inventory) => {
        inventory.batches.forEach((batch) => {
          batch.selectedAmount = null
        })
      })
    },
  },
  computed: {
    openProduct() {
      if (this.product.displayOpenProduct) {
        return { display: 'block' }
      } else {
        return { display: 'none' }
      }
    },
  },
}
</script>

<style scoped lang="scss">
.noProductsFound {
  font-size: 15pt;
  color: rgb(255, 91, 91);
}
.errorMsg p {
  float: right;
  color: red;
  margin-right: 5px;
  font-family: 'Spartan';
  font-size: 10pt;
}
.errorMsg {
  width: 100%;
  position: relative;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.autoSelect p {
  margin-right: 5px;
}
.autoSelect {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}
.topInfo p span {
  font-size: 10pt;
  color: rgb(151, 151, 151);
  margin-left: 5px;
}
.topInfo p {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15pt;
  margin: 3px 0;
  color: rgb(112, 112, 112);
}
.topInfo {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
}
.summary div p {
  font-size: 12pt;
  color: #333;
}
.summary div span {
  font-size: 15pt;
  color: $accent;
}
.summary section p {
  margin-right: 10px;
}
.summary section {
  display: flex;
}
.summary {
  margin-top: 10px;
}
.openProductWrapper {
  position: relative;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  // transition: 0.2s ease-in-out;
  overflow: hidden;
}
.openProduct {
  margin: 10px 0;
  position: relative;
}
</style>