<template>
  <div class="customBox boxDesign">
    <div class="searchInput">
      <ion-icon name="search-outline"></ion-icon>
      <input type="text" v-model="search.value" :placeholder="placeholder" />
    </div>
  </div>
</template>

<script>
export default {
  props: ['placeholder', 'changePage', 'search'],
}
</script>

<style scoped>
.customBox {
  grid-column-start: 2;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 1;
}
.boxContent {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
#arrow {
  font-size: 20pt;
  cursor: pointer;
  padding: 10px;
  color: rgb(145, 145, 145);
  transition: 0.2s ease-in-out;
}
#arrow:hover {
  font-size: 20pt;
  color: rgb(75, 75, 75);
}
.searchInput {
  border: none;
  position: relative;
  width: 90%;
  font-size: 10pt;
  border-radius: 7px;
  display: flex;
  padding: 0 10px;
  box-shadow: inset 0px 0px 2px 1px rgb(219 219 219);
  justify-content: center;
  align-items: center;
}

.searchInput input {
  border: none;
  background: none;
  width: 90%;
  position: relative;
  padding: 5px 0;
  transition: 1s;
}

.searchInput ion-icon {
  font-size: 12pt;
  color: #666;
  cursor: pointer;
  margin: 0;
  margin-right: 5px;
}
</style>