<template>
  <div class="storageWrapper" v-if="storageLocation">
    <div v-for="productLocation in productLocations" :key="productLocation.id">
      <div>
        <p>{{ storageLocation.name }}</p>
      </div>
      <table class="standardTable">
        <tr class="standardTableHeader" >
          <th>ID</th>
          <th>Amount</th>
          <th>Location</th>
          <th>Price</th>
        </tr>
        <tr class="tableRow" v-for="batch in productLocation.batches" :key="batch.id">
          <td>
            {{batch.batchNumber}}
          </td>
          <td>
            {{batch.amount}} {{batch.unit}}
          </td>
          <td>
            {{batch.storage_location_id}}
          </td>
          <td>
            {{batch.batch_price}} kr
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  props: ['storageLocation', 'product'],
  data() {
    return {
      productLocations: []
    }
  },
  created() {
    this.getProductLocations()
  },
  methods: {
    getProductLocations() {
      axios.post('pos/getProductsInLocation', {
        productId: this.product.id,
        selectedShop: this.storageLocation.id,
      }).then((response) => {
        this.productLocations = response.data
      })
    }
  }
}
</script>

<style scoped lang="scss">
.batchRow {
  width: 100%;
  position: relative;
  margin: 10px auto;
  background: #333;
  border-radius: 5px;
  color: white;
}
</style>