<template>
  <!-- Unit select -->
  <div class="units">
    <div class="unitWrapper">
      <div class="selectStorage" v-if="storageLocations" @click.stop>
        <StorageSelect :product="product" :storageLocation="storageLocation" v-for="storageLocation in storageLocations" :key="storageLocation.id" />
      </div>
      <div class="selectUnit">
        <p
          @click.stop="
            () => {
              applyUnit('kilo')
            }
          "
        >
          Kilo
        </p>
        <p
          @click.stop="
            () => {
              applyUnit('st')
            }
          "
        >
          Pieces
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import StorageSelect from '@/components/pos/StorageSelect'
export default {
  components: { StorageSelect },
  props: ['product', 'applyUnit'],
  data() {
    return {
      storageLocations: []
    }
  },
  created() {
    this.getStorage()
  },
  methods: {
    getStorage() {
      axios.post('pos/getStorageLocations', {
        selectedShop: this.$store.state.selectedShop,
      }).then((response) => {
        this.storageLocations = response.data
      })

    }
  }
}
</script>

<style scoped lang="scss">
.selectStorage {
  background: white;
  padding: 20px;
  width: 90%;
  margin: 15px auto;
  border-radius: 5px;
}
.units {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  top: 0%;
  left: 0%;
  z-index: 20;
  justify-content: center;
  align-items: center;
  display: flex;
}
.unitWrapper {
  display: block;
}
.selectUnit {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  position: relative;
}
.selectUnit p {
  padding: 50px;
  width: 200px;
  background: white;
  color: #333;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 20px;
}
</style>