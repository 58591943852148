<template>
  <fragment>
    <!-- Loader -->
    <Loader v-if="products == null" />

    <!-- No products -->
    <div class="products" v-else-if="products.length == 0">
      <div class="boxDesign">No products found</div>
    </div>

    <!-- Products -->
    <div class="products" v-else>
      <div
        v-if="selectedCategory"
        @click="changeSelectedCategory(null)"
        class="boxDesign category"
      >
        {{ selectedCategory.group_name }}
      </div>
      <div
        v-if="
          products.length >
            (selectedCategory ? numberOfProducts - 1 : numberOfProducts) &&
          currentPage != 0
        "
        @click="changePage(currentPage == 0 ? 0 : -1)"
        class="boxDesign next"
      >
        <p>Prev</p>
      </div>
      <DisplayProducts
        v-for="product in filtredProducts(
          products,
          selectedCategory
            ? currentPage == 0
              ? numberOfProducts - 2
              : numberOfProducts - 3
            : currentPage == 0
            ? numberOfProducts - 1
            : numberOfProducts - 2
        )"
        :key="product.product_id"
        :addProduct="addProduct"
        :product="product"
        class="boxDesign product"
      />
      <div
        v-if="
          products.length >
            (selectedCategory ? numberOfProducts - 1 : numberOfProducts) &&
          (currentPage + 1) *
            (selectedCategory ? numberOfProducts - 3 : numberOfProducts - 2) <=
            products.length
        "
        @click="changePage(1)"
        class="boxDesign next"
      >
        <p>Next</p>
      </div>
    </div>
  </fragment>
</template>

<script>
import axios from 'axios'
import Search from '@/components/pos/gridComponents/Search'
import DisplayProducts from '@/components/pos/gridComponents/DisplayProducts'
import Loader from '@/components/pos/gridComponents/Loader'
import { mapGetters } from 'vuex'

export default {
  props: [
    'addProduct',
    'changeCurrentDisplay',
    'selectedCategory',
    'changeSelectedCategory',
    'currentPage',
    'searchString',
    'changePage',
  ],
  components: { Search, DisplayProducts, Loader },
  data() {
    return {
      products: [],
      numberOfProducts: 36
    }
  },
  computed: {
    ...mapGetters({
      getLocationId: 'getLocationId',
    })
  },
  watch: {
    searchString() {
      this.getProducts()
    },
    selectedCategory() {
      this.getProducts()
    },
    getLocationId() {
      this.getProducts();
    }
  },
  created() {
    this.getProducts()
  },
  methods: {
    filtredProducts(array, items) {
      //Check if new items is less
      let t = []
      if ((this.currentPage + 1) * items > array.length) {
        for (let index = this.currentPage * items; index < array.length; index++) {
          t.push(array[index]);
        }
      } else {
        for (let index = this.currentPage * items; index < (this.currentPage + 1) * items; index++) {
          t.push(array[index]);
        }
      }

      return t
    },
    getProducts() {
      this.products = null
      if (this.$store.state.selectedShop == null) {
        this.products = []
        return
      }
      axios.post('pos/getProductsInLocation', { shopId: this.$store.state.selectedShop, searchString: this.searchString }).then((response) => {
        this.products = response.data
      })
    }
  },
}
</script>

<style scoped lang="scss">
.products {
  grid-column-start: 5;
  grid-column-end: 10;
  grid-row-start: 2;
  grid-row-end: 4;
  display: grid;
  grid-auto-rows: 80px;
  grid-template-columns: repeat(6, minmax(75px, 1fr));
  column-gap: 10px;
  row-gap: 0.5em;
}
.product {
  cursor: pointer;
}
.category {
  border: 3px solid rgb(255, 109, 109) !important;
  color: rgb(65, 65, 65);
  font-style: italic;
  cursor: pointer;
  background: white !important;
}
.next {
  background: $accent !important;
  color: white;
  cursor: pointer;
}

.optionsNumpad div:hover {
  border: 1px solid $accent;
}
</style>