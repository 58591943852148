<template>
  <div class="loader">
    <div class="boxDesign"></div>
    <div class="boxDesign"></div>
    <div class="boxDesign"></div>
    <div class="boxDesign"></div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
@keyframes example {
  0% {
    background-color: rgb(255, 255, 255);
  }
  25% {
    background-color: rgb(194, 194, 194);
  }
  50% {
    background-color: rgb(255, 255, 255);
  }
  100% {
    background-color: rgb(255, 255, 255);
  }
}

.boxDesign {
  animation-name: example;
  animation-iteration-count: infinite;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
}

.boxDesign:nth-child(1) { animation-delay: 0; }
.boxDesign:nth-child(2) { animation-delay: .2s; }
.boxDesign:nth-child(3) { animation-delay: .4s; }
.boxDesign:nth-child(4) { animation-delay: .6s; }

.loader {
  grid-column-start: 5;
  grid-column-end: 10;
  grid-row-start: 2;
  grid-row-end: 2;
  display: grid;
  grid-auto-rows: 80px;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  column-gap: 10px;
  row-gap: 0.5em;
}
</style>