<template>
  <div class="popupBox">
    <div class="products">
      <div class="product" v-for="product in products" :key="product.id">
        <p>{{ product }}</p>
        <p>Hello</p>
      </div>
    </div>
    <div>
      <br />
      <MainButton
        :clickFunction="addOrder"
        title="Add order"
        styleClass="primary"
        :buttonStyle="{ width: '100%', padding: '40px 0' }"
      />
    </div>
    <div>
      <br />
      <MainButton
        :clickFunction="closePopup"
        title="Cancel"
        styleClass="secondary"
        :buttonStyle="{ width: '100%', padding: '40px 0' }"
      />
    </div>
  </div>
</template>

<script>
import MainButton from '@/layout/MainButton.vue'
import axios from 'axios'

export default {
  components: { MainButton },
  props: ['products', 'addOrder', 'error'],
  data() {
    return {
      fieldName: '',
      fieldType: undefined
    }
  },
  methods: {
    closePopup() {
      this.$store.commit('CLEAR_POPUP')
    }
  }
}
</script>

<style scoped src="@/assets/css/popup.css">
.product {
  background: red !important;
}
.products{
  width: 200px;
  background: gray;
}
</style>
