<template>
  <tr class="batchTableRow" v-if="batch.unit == product.unit">
    <td>#{{ batch.batchNumber }}</td>
    <td>
      {{ batch.amountAvailable }}
      {{ batch.unit }}
    </td>
    <td>
      {{ formatTime(batch.expiration_date) }}
    </td>
    <td>
      <input
        @click.stop="setSelectedElement($event.target, batch)"
        type="text"
        class="amountInput"
        placeholder="0"
        :style="calcBorder"
        v-model="batch.selectedAmount"
      />
    </td>
    <td
      v-if="
        batch.amountAvailable == 0 ||
          batch.selectedAmount == 0 ||
          batch.selectedAmount == null
      "
    ></td>
    <td v-else><md-switch v-model="batch.empty" /></td>
  </tr>
</template>

<script>
import MainButton from '@/layout/MainButton.vue'
import axios from 'axios'

export default {
  props: [
    'batch',
    'product',
    'index',
    'setSelectedElement',
    'inventoryIndex',
    'setErrorMessage',
    'orderInfo',
    'products',
    'calcPrice'
  ],
  components: { MainButton },
  watch: {
    'batch.selectedAmount': function() {
//      Update price
      if (!Number(this.batch.selectedAmount)) {
        this.batch.selectedAmount = null
      } 

      axios
        .post('pos/lockBatch', {
          userId: this.$store.state.selectedUser.id,
          shopId: this.$store.state.selectedShop,
          batch: this.batch
        })
        .then(() => {
          this.$socket.emit('updateProducts', {
            companyId: this.$store.state.selectedUser.companyId
          })
          this.calcPrice()
        })
    },
    'batch.empty': function() {
      axios
        .post('pos/lockBatchEmpty', {
          userId: this.$store.state.selectedUser.id,
          shopId: this.$store.state.selectedShop,
          batch: this.batch
        })
        .then(response => {
          this.$socket.emit('updateProducts', {
            companyId: this.$store.state.selectedUser.companyId
          })
          this.calcPrice()
        })
    }
  },
  computed: {
    calcBorder() {
      if (this.batch.selectedAmount != null && this.batch.selectedAmount != 0) {
        return { 'border-bottom': '2px solid green' }
      } else {
        return { 'border-bottom': '1px solid gray' }
      }
    }
  },
  methods: {
    formatTime(date) {
      let newDate = new Date(date)
      return newDate
        .toString()
        .split('GMT')[0]
        .substr(4, 11)
    }
  }
}
</script>

<style scoped lang="scss">
.activeInput {
  border-bottom: 2px solid $accent !important;
}
.endTd {
  display: flex;
  justify-content: left;
  align-items: center;
}
.endTd ion-icon {
  cursor: pointer;
  color: rgb(255, 255, 255);
  background: #333;
  padding: 4px;
  border-radius: 50%;
  top: 4px;
  position: relative;
}
.inputBox {
  display: flex;
  justify-content: center;
  align-items: center;
}
.amountInput {
  width: 75px;
  padding: 5px;
  border: none;
  border-bottom: 1px solid rgb(180, 180, 180);
  color: #333;
  box-sizing: content-box;
}

.amountInput::-webkit-outer-spin-button,
.amountInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.batch p {
  margin-right: 5px;
}
.batch {
  width: 100%;
  position: relative;
  padding: 5px 0;
  border-radius: 5px;
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.batchTableRow {
  height: 60px;
}
</style>
