<template>
  <div class="numPadOptions">
    <div class="boxDesign" @click="setValue()"><p>Enter</p></div>
    <div class="boxDesign" @click="clearValue()"><p>Clear</p></div>
  </div>
</template>

<script>
export default {
  props: ['setValue', 'clearValue']
}
</script>

<style scoped lang="scss">
.numPadOptions {
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 6;
  grid-row-end: 10;
  display: grid;
  grid-auto-rows: 80px;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 10px;
  row-gap: 0.5em;
}
.boxDesign {
  cursor: pointer;
}
.numPad div:hover {
  border: 3px solid $accent;
}
</style>