<template>
  <div class="numPad">
    <div class="boxDesign" @click="createValue('1')"><p>1</p></div>
    <div class="boxDesign" @click="createValue('2')"><p>2</p></div>
    <div class="boxDesign" @click="createValue('3')"><p>3</p></div>
    <div class="boxDesign" @click="createValue('*')"><p>*</p></div>
    <div class="boxDesign" @click="createValue('4')"><p>4</p></div>
    <div class="boxDesign" @click="createValue('5')"><p>5</p></div>
    <div class="boxDesign" @click="createValue('6')"><p>6</p></div>
    <div class="boxDesign" @click="createValue('/')"><p>/</p></div>
    <div class="boxDesign" @click="createValue('7')"><p>7</p></div>
    <div class="boxDesign" @click="createValue('8')"><p>8</p></div>
    <div class="boxDesign" @click="createValue('9')"><p>9</p></div>
    <div class="boxDesign" @click="createValue('+')"><p>+</p></div>
    <div class="boxDesign" @click="createValue('.')"><p>.</p></div>
    <div class="boxDesign" @click="createValue('0')"><p>0</p></div>
    <div class="boxDesign" @click="createValue('%')"><p>%</p></div>
    <div class="boxDesign" @click="createValue('-')"><p>-</p></div>
  </div>
</template>

<script>
export default {
  props: ['createValue']
}
</script>

<style scoped lang="scss">
.numPad {
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 6;
  grid-row-end: 10;
  display: grid;
  grid-auto-rows: 80px;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 10px;
  row-gap: 0.5em;
}
.boxDesign {
  cursor: pointer;
}
.boxDesign p {
  font-size: 18pt;
}
.numPad div:hover {
  border: 3px solid $accent;
}
</style>