<template>
  <div class="boxDesign">
    <p style="margin-right:5px;">Scale:</p>
    <input type="text" placeholder="Scale input" v-model="scale.value">
  </div>
</template>

<script>
export default {
  props: ['scale'],
  created() {
    this.scale.value = Math.round(Math.random() * 100) / 10
  }
}
</script>

<style scoped>
.boxDesign input {
  width: 75px;
  padding: 8px;
  border:1px solid rgb(187, 187, 187);
  border-radius: 5px;
}
</style>