<template>
  <div class="categories">
    <div @click="getProducts(categorie)" class="boxDesign categorie" v-for="categorie in categories" :key="categorie.id">
      {{ categorie.group_name }}
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Search from '@/components/pos/gridComponents/Search'

export default {
  props: ['changeCurrentDisplay', 'changeSelectedCategory', 'searchString'],
  components: { Search },
  data() {
    return {
      categories: [],
    }
  },
  watch: {
    searchString() {
      this.getGroups()
    }
  },
  created() {
    this.getGroups()
  },
  methods: {
    getProducts(data) {
      this.changeSelectedCategory(data)
      this.changeCurrentDisplay('products')
    },
    getGroups() {
      axios.post('pos/getGroups', { searchString: this.searchString }).then((response) => {
        this.categories = response.data
      })
    }
  },
}
</script>

<style scoped lang="scss">
.categories {
  grid-column-start: 5;
  grid-column-end: 10;
  grid-row-start: 2;
  grid-row-end: 4;
  display: grid;
  grid-auto-rows: 80px;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 10px;
  row-gap: 0.5em;
}
.categorie {
  cursor: pointer;
}
.customBox {
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 1;
  display: grid;
  grid-auto-rows: 80px;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 10px;
  row-gap: 0.5em;
}
.optionsNumpad div:hover {
  border: 1px solid $accent;
}
</style>