<template>
  <div class="product" @click="add()">
      {{ product.productName }}
  </div>
</template>

<script>
import UnitSelect from '@/components/pos/UnitSelect'
export default {
  props: ['product', 'addProduct'],
  components: { UnitSelect },
  methods: {
    add() {
      this.product.displayOpenProduct = true
      this.addProduct(this.product)
    }
  }
}
</script>

<style scoped lang="scss">
</style>