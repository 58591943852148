<template>
  <div>
    <div @click="openProduct()" class="product">
      <div class="indicator">
        <p style="width: 120px">
          {{ product.productName }}
          <span>({{ getTotalAmount() }} {{ product.unit }})</span>
        </p>
      </div>
      <div class="select">
        <p>Amount: {{ productSelectedAmount }}</p>
        <p @click.stop="switchUnit()" class="unitSelect">
          {{ product.unit }}
        </p>
      </div>
      <ion-icon @click="removeProduct(index)" name="trash-outline"></ion-icon>
    </div>
    <OpenProduct
      :calcPrice="calcPrice"
      :products="products"
      :orderInfo="orderInfo"
      :setSelectedElement="setSelectedElement"
      :product="product"
    />
  </div>
</template>

<script>
import UnitSelect from '@/components/pos/UnitSelect'
import OpenProduct from '@/components/pos/gridComponents/OpenProduct'

export default {
  props: [
    'product',
    'setSelectedElement',
    'index',
    'removeProduct',
    'createValue',
    'orderInfo',
    'products',
    'calcPrice'
  ],
  components: { UnitSelect, OpenProduct },
  data() {
    return {
      objHeight: 0,
      heightFound: false
    }
  },
  computed: {
    productSelectedAmount() {
      let totalAvalible = 0
      this.product.inventories.forEach(inventory => {
        totalAvalible += inventory.batches.reduce((prevAmount, batch) => {
          if (batch.unit == this.product.unit)
            return prevAmount + Number(batch.selectedAmount)
          return 0
        }, 0)
      })
      this.product.totalAmount = totalAvalible
      return totalAvalible
    }
  },
  methods: {
    switchUnit() {
      this.product.unit == 'kilo'
        ? (this.product.unit = 'pieces')
        : (this.product.unit = 'kilo')

      let found
      this.product.inventories.forEach(inventory => {
        found = inventory.batches.some(batch => batch.unit == this.product.unit)
      })

      if (!found) {
        this.$store.commit('ADD_ALERT', {
          type: 'error',
          title: 'Something went wrong!',
          description:
            this.capitalizeFirstLetter(this.product.unit) +
            ' does not exsists on ' +
            this.product.productName,
          time: 5000
        })
        this.product.unit == 'kilo'
          ? (this.product.unit = 'pieces')
          : (this.product.unit = 'kilo')
      }
    },
    getTotalAmount() {
      let totalAvalible = 0
      this.product.inventories.forEach(inventory => {
        totalAvalible += inventory.batches.reduce((prevAmount, batch) => {
          if (batch.unit == this.product.unit)
            return prevAmount + batch.amountAvailable
          return 0
        }, 0)
      })
      return totalAvalible
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    openProduct() {
      this.product.displayOpenProduct = !this.product.displayOpenProduct
    }
  }
}
</script>

<style scoped lang="scss">
.select {
  display: flex;
  justify-content: center;
  align-items: center;
}
.indicator {
  display: flex;
  align-items: center;
  justify-content: center;
}
.finished div {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: red;
  position: absolute;
  top: 2px;
  left: 2px;
}
.finished {
  width: 16px;
  height: 16px;
  background: white;
  border-radius: 50%;
  margin-right: 5px;
  position: relative;
}
.amountInput {
  width: 50px;
  padding: 11px 10px;
  border: none;
  border-radius: 5px;
  border: 1px solid white;
  color: #333;
  margin-right: 10px;
  box-sizing: content-box;
}

.amountInput::-webkit-outer-spin-button,
.amountInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.product p {
  margin-right: 10px;
}
.product ion-icon {
  color: rgb(255, 255, 255);
  font-size: 20pt;
  cursor: pointer;
}
.unitSelect {
  cursor: pointer;
  background: rgb(255, 255, 255);
  padding: 10px 20px;
  border-radius: 5px;
  color: #333;
  width: 75px;
  text-align: center;
  width: 100px;
}
.product {
  background: $accent;
  color: white;
  margin-bottom: 5px;
  padding: 10px 10px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.activeInput {
  background: rgb(59, 168, 73);
  color: white;
  border: 1px solid white;
}

.activeInput::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(255, 255, 255);
  opacity: 1; /* Firefox */
}
</style>
