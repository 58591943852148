<template>
  <div
    @click="setSelectedElement(null, null)"
    class="orderInformationBox orderDesign"
  >
    <div v-if="products.length > 0" class="header">
      <div class="totalPrice">
        Total (kr): <input @click.stop="setSelectedElement($event.target, totalPrice)" type="text" v-model="totalPrice" />
      </div>
    </div>
    <div v-if="products.length == 0">
      <p class="noProducts">No products added</p>
      <p style="text-align: center">
        Add a product from the list to see it appear here
      </p>
    </div>
    <div v-else class="products" v-dragscroll>
      <OrderInfoProduct
        :calcPrice="calcPrice"
        :productSelectedAmount="productSelectedAmount"
        :createValue="createValue"
        :index="index"
        :removeProduct="removeProduct"
        :setSelectedElement="setSelectedElement"
        :product="product"
        v-for="(product, index) in products"
        :key="product.id"
      />
    </div>
  </div>
</template>

<script>
import OrderInfoProduct from '@/components/pos/gridComponents/OrderInfoProduct'
import { dragscroll } from 'vue-dragscroll'
export default {
  directives: {
    dragscroll
  },
  components: { OrderInfoProduct },
  props: [
    'products',
    'setSelectedElement',
    'removeProduct',
    'createValue',
    'displayOrderInfo',
    'productSelectedAmount',
    'calcPrice',
    'totalPrice'
  ],
}
</script>

<style scoped lang="scss">
.totalPrice input {
  border: none;
  width: 100px;
  margin-left: 20px;
  font-size: 20pt;
  color: $accent;
  border-bottom: 1px solid rgb(172, 172, 172);
}
.header {
  display: flex;
  align-items: center;
}
.totalPrice {
  margin: 10px;
  font-size: 8pt;
  color: rgb(168, 168, 168);
}
.totalPrice span {
  font-size: 15pt;
  margin-left: 5px;
  color: $accent;
}
.products {
  height: 90%;
  position: relative;
  overflow: hidden;
}
.noProducts {
  text-align: center;
  font-size: 15pt;
  margin: 10px;
}
.orderInformationBox {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 6;
  overflow:hidden;
}
.orderDesign {
  padding: 10px;
  background: white;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px rgb(200 200 200);
  word-break: break-word;
  font-size: 10pt;
}
</style>
