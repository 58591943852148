<template>
  <div class="topBox">
    <Search :changePage="changePage" :search="search" :placeholder="'Search product'" />
    <div :class="'category boxDesign ' + (currentDisplay == 'products' ? 'selected' : '')" @click="changeCurrentDisplay('products')">Products</div>
  </div>
</template>

<script>
import Search from '@/components/pos/gridComponents/Search'

export default {
  components: { Search },
  props: [
    'currentDisplay', 
    'changeCurrentDisplay', 
    'changePage',
    'search'
    ],
}
</script>

<style scoped lang="scss">
.topBox {
  grid-column-start: 5;
  grid-column-end: 10;
  grid-row-start: 1;
  grid-row-end: 2;
  display: grid;
  grid-auto-rows: 80px;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 10px;
  row-gap: 0.5em;
}
.boxDesign {
  background: white !important;
  color: #333;
  cursor: pointer;
  font-style: italic;
}
.selected, .category:hover {
  background: $accent !important;
  color: white;
}
</style>