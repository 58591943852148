var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[(_vm.products == null)?_c('Loader'):(_vm.products.length == 0)?_c('div',{staticClass:"products"},[_c('div',{staticClass:"boxDesign"},[_vm._v("No products found")])]):_c('div',{staticClass:"products"},[(_vm.selectedCategory)?_c('div',{staticClass:"boxDesign category",on:{"click":function($event){return _vm.changeSelectedCategory(null)}}},[_vm._v(" "+_vm._s(_vm.selectedCategory.group_name)+" ")]):_vm._e(),(
        _vm.products.length >
          (_vm.selectedCategory ? _vm.numberOfProducts - 1 : _vm.numberOfProducts) &&
        _vm.currentPage != 0
      )?_c('div',{staticClass:"boxDesign next",on:{"click":function($event){return _vm.changePage(_vm.currentPage == 0 ? 0 : -1)}}},[_c('p',[_vm._v("Prev")])]):_vm._e(),_vm._l((_vm.filtredProducts(
        _vm.products,
        _vm.selectedCategory
          ? _vm.currentPage == 0
            ? _vm.numberOfProducts - 2
            : _vm.numberOfProducts - 3
          : _vm.currentPage == 0
          ? _vm.numberOfProducts - 1
          : _vm.numberOfProducts - 2
      )),function(product){return _c('DisplayProducts',{key:product.product_id,staticClass:"boxDesign product",attrs:{"addProduct":_vm.addProduct,"product":product}})}),(
        _vm.products.length >
          (_vm.selectedCategory ? _vm.numberOfProducts - 1 : _vm.numberOfProducts) &&
        (_vm.currentPage + 1) *
          (_vm.selectedCategory ? _vm.numberOfProducts - 3 : _vm.numberOfProducts - 2) <=
          _vm.products.length
      )?_c('div',{staticClass:"boxDesign next",on:{"click":function($event){return _vm.changePage(1)}}},[_c('p',[_vm._v("Next")])]):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }