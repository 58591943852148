<template>
  <div class="pageWrapper">
    <div class="gridWrapper">
      <OrderInfo
        :totalPrice="totalPrice"
        :calcPrice="calcPrice"
        :setSelectedElement="setSelectedElement"
        :removeProduct="removeProduct"
        :products="products"
        :createValue="createValue"
      />
      <TopBox
        :changePage="changePage"
        :search="search"
        :currentDisplay="currentDisplay"
        :changeCurrentDisplay="changeCurrentDisplay"
      />

      <Numpad
        :clearValue="clearValue"
        :createValue="createValue"
        :setValue="setValue"
      />
      <OptionsNumpad :clearValue="clearValue" :setValue="setValue" />
      <OptionsOrder
        :addOrder="togglePopup"
        :scale="scale"
        :addScaleValue="addScaleValue"
      />

      <!-- Fetchable items -->
      <Categories
        :changeCurrentDisplay="changeCurrentDisplay"
        :searchString="search.value"
        v-if="currentDisplay == 'categories'"
      />
      <Products
        :products="products"
        :changePage="changePage"
        :searchString="search.value"
        :currentPage="currentPage"
        :selectedCategory="selectedCategory"
        :changeCurrentDisplay="changeCurrentDisplay"
        :addProduct="addProduct"
        v-if="currentDisplay == 'products'"
      />
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Numpad from '@/components/pos/Numpad'
import OptionsOrder from '@/components/pos/OptionsOrder'
import Products from '@/components/pos/Products'
import Categories from '@/components/pos/Categories'
import TopBox from '@/components/pos/TopBox'
import OrderInfo from '@/components/pos/OrderInfo'
import OptionsNumpad from '@/components/pos/OptionsNumpad'
import AddOrderPopup from '@/components/pos/gridComponents/AddOrderPopup'

import { mapGetters } from 'vuex'

export default {
  components: {
    Numpad,
    OptionsOrder,
    Categories,
    TopBox,
    Products,
    OrderInfo,
    OptionsNumpad,
    AddOrderPopup
  },

  data() {
    return {
      currentDisplay: 'products',
      currentPage: 0,
      search: { value: '' },

      selectedObj: null,
      selectedType: null,
      selectedCategory: null,

      scale: { value: 0 },
      totalPrice: 0,

      products: [],
      selectedElement: null,
      selectedElementIndex: null,
      tempVal: '',

      displayOrderInfo: true
    }
  },
  sockets: {
    posUpdateProducts() {
      this.updateProducts()
    }
  },
  computed: {
    ...mapGetters({
      getUser: 'getUser'
    })
  },
  watch: {
    getUser() {
      this.setProductsFromStore()
    }
  },
  mounted() {
    this.setProductsFromStore()
  },
  methods: {
    //Add the order
    togglePopup() {
      if (this.products.length == 0) {
        alert('You need atleast one product')
      } else {
        this.$store.commit('SET_POPUP', {
          component: AddOrderPopup,
          properties: { addOrder: this.addOrder, products: this.products }
        })
      }
    },
    setProductsFromStore() {
      let index = this.$store.state.posOrders.findIndex(
        x => x.employeeId == this.$store.state.selectedUser.id
      )
      this.products =
        index != -1 ? this.$store.state.posOrders[index].products : []
    },
    addOrder() {
      // Add the order to the system
      axios
        .post('pos/addOrder', {
          products: this.products,
          selectedShop: this.$store.state.selectedShop,
          userId: this.$store.state.selectedUser.id,
          totalPrice: this.totalPrice
        })
        .then(response => {
          if (response.data) {
            this.$store.commit('CLEAR_POPUP')
            this.$store.commit('REMOVE_POS_PRODUCTS', {
              id: this.$store.state.selectedUser.id
            })
            this.$socket.emit('updateProducts', {
              companyId: this.$store.state.selectedUser.companyId
            })
            this.Products = []
            this.setProductsFromStore()
          }
        })
    },
    //Selected product in order information
    setSelectedElement(element, obj) {
      this.selectedObj = obj

      if (element == null) {
        // If a selected input exsists, remove that and return function
        if (this.selectedElement) {
          this.selectedElement.classList.remove('activeInput')
          this.selectedElement = null
        }
        return
      }

      //Reset element
      if (this.selectedElement)
        this.selectedElement.classList.remove('activeInput')

      //Set new element
      this.selectedElement = element
      this.selectedElement.classList.add('activeInput')
      this.tempVal = this.selectedElement.value
    },
    changePage(val) {
      if (this.currentPage == 0 && val == -1) return
      this.currentPage += val
    },
    createValue(val) {
      if (this.selectedElement) {
        this.tempVal = this.tempVal == '0' ? '' : this.tempVal
        this.tempVal += val
        this.selectedElement.value = this.tempVal
      }
    },
    async setValue() {
      if (this.selectedElement) {
        let evaluatedNumber = Number(eval(this.tempVal)).toFixed(3)

        if (typeof this.selectedObj == 'object')
          this.$set(this.selectedObj, 'selectedAmount', evaluatedNumber)
        else this.selectedObj = evaluatedNumber

        this.selectedElement.value = evaluatedNumber
        this.selectedElement.classList.remove('activeInput')
        this.selectedElement = null

        this.updateStore()
      }
    },
    clearValue() {
      if (this.selectedElement) {
        if (typeof this.selectedObj == 'object')
          this.selectedObj.selectedAmount = null
        else this.selectedObj = 0

        this.selectedElement.value = 0
        this.tempVal = ''
      }
    },
    removeProduct(index) {
      let tmpProduct = this.products[index]
      axios
        .post('pos/removeLockedBatch', {
          userId: this.$store.state.selectedUser.id,
          shopId: this.$store.state.selectedShop,
          product: tmpProduct
        })
        .then(response => {
          this.$socket.emit('updateProducts', {
            companyId: this.$store.state.selectedUser.companyId
          })
          this.$store.commit('REMOVE_POS_PRODUCT', {
            id: this.$store.state.selectedUser.id,
            index: index
          })
        })
    },
    async getBatchInfo(firstTime) {
      for (let product of this.products) {
        for (let inventory of product.inventories) {
          for (let batch of inventory.batches) {
            await axios
              .post('pos/getBatchInfo', {
                userId: this.$store.state.selectedUser.id,
                shopId: this.$store.state.selectedShop,
                batch: batch
              })
              .then(response => {
                // Get avalibale left
                let amountAvailable = Number(batch.amount)
                let scaleValue = Number(this.scale.value) // Insert scale value here

                if (response.data.locked.length > 0)
                  response.data.locked.forEach(lock => {
                    amountAvailable -= lock.amount
                  })

                amountAvailable = Number(amountAvailable.toFixed(15))

                // Errro handeling
                if (amountAvailable < 0) {
                  this.$store.commit('ADD_ALERT', {
                    type: 'error',
                    title: 'Something went wrong!',
                    description: 'You tried to add more then is avalible',
                    time: 5000
                  })
                  batch.selectedAmount = null
                  return
                }

                if (firstTime) {
                  if (product.inventories.length == 1) {
                    if (inventory.batches.length == 1) {
                      // If more then avalible
                      if (scaleValue > amountAvailable) {
                        this.$store.commit('ADD_ALERT', {
                          type: 'error',
                          title: 'Something went wrong!',
                          description:
                            'You tried to add more then is avalible from the scale',
                          time: 5000
                        })
                        this.$set(batch, 'locked', response.data.locked)
                        this.$set(batch, 'amountAvailable', amountAvailable)
                        return
                      }
                      this.$set(batch, 'selectedAmount', scaleValue)
                    }
                  }
                }

                this.$set(batch, 'locked', response.data.locked)
                this.$set(batch, 'amountAvailable', amountAvailable)
              })
          }
        }
      }
      return
    },
    async updateProducts() {
      await this.getBatchInfo(false)
      this.updateStore()
    },
    async loadProductsInfo() {
      await this.getBatchInfo(true)
    },
    async addProduct(product) {
      await axios
        .post('pos/getProductInfo', {
          userId: this.$store.state.selectedUser.id,
          shopId: this.$store.state.selectedShop,
          product: product
        })
        .then(async response => {
          product = response.data
          if (
            !this.products.some(storedPoduct => storedPoduct.id == product.id)
          ) {
            // Add unit
            let unit = product.inventories[0].batches[0].unit
            product.unit = String(unit)

            // Adding products to the store
            this.$store.commit('ADD_POS_PRODUCT', {
              id: this.$store.state.selectedUser.id,
              product: product
            })

            this.setProductsFromStore()
            await this.loadProductsInfo()

            //Close everyone else and open this one
            this.products.forEach(tProduct => {
              tProduct.displayOpenProduct = false
            })

            product.displayOpenProduct = true

            this.updateStore()
          } else {
            this.$store.commit('ADD_ALERT', {
              type: 'error',
              title: 'Something went wrong!',
              description: 'You have already added ' + product.productName,
              time: 5000
            })
          }
        })
    },
    updateStore() {
      this.$store.commit('UPDATE_POS_PRODUCTS', {
        id: this.$store.state.selectedUser.id,
        products: this.products
      })
    },
    changeCurrentDisplay(data) {
      this.search.value = ''
      this.currentDisplay = data
    },
    addScaleValue() {
      //Check so the amount is avalible
      if (Number(this.scale.value) <= this.selectedObj.amountAvailable) {
        this.tempVal = this.scale.value
        this.setValue()
      } else {
        this.$store.commit('ADD_ALERT', {
          type: 'error',
          title: 'Something went wrong!',
          description:
            'You can not add more then avalible in batch: ' +
            this.selectedObj.batchNumber,
          time: 5000
        })
      }
    },
    calcPrice() {
      let totalPriceT = 0
      if (this.products.length > 0) {
        this.products.forEach(product => {
          product.inventories.forEach(inventory => {
            inventory.batches.forEach(batch => {
              totalPriceT +=
                Number(batch.selectedAmount) *
                Number(
                  product.unit == 'kilo'
                    ? product.productKiloPrice
                    : product.productUnitPrice
                )
            })
          })
        })
      }
      this.totalPrice = totalPriceT
    }
  }
}
</script>

<style lang="scss">
.gridWrapper {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-auto-rows: 80px;
  column-gap: 10px;
  row-gap: 0.5em;
}

//Design for all boxes
.gridWrapper .boxDesign {
  padding: 5px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px rgb(200 200 200);
  word-break: break-word;
  font-size: 10pt;
}
</style>
