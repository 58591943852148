<template>
  <fragment>
    <div class="optionsOrderTop">
      <div
        @click="showOptions = !showOptions"
        v-if="shops.length > 0"
        class="boxDesign"
        style="position: relative"
      >
        <div v-if="showOptions && shops.length > 0" class="shopsList">
          <div
            @click.stop="changeShop(shop.id)"
            v-for="shop in shops"
            :key="shop.id"
          >
            {{ shop.locationName }}
          </div>
        </div>
        <p class="selectedShop">
          {{ getShopName(this.$store.state.selectedShop) }}
        </p>
      </div>
      <!-- <Scale :scale="scale" /> -->
    </div>
    <div class="optionsOrder">
      <div
        class="boxDesign"
        @click="addOrder()"
        style="background: #46db70; color: white"
      >
        <p>Add order</p>
      </div>
      <div class="boxDesign" @click="addScaleValue()"><p>Scale</p></div>
      <div class="boxDesign"></div>
      <div class="boxDesign"></div>
      <div class="boxDesign"></div>
      <div
        class="boxDesign"
        @click="goFullscreen()"
        style="background: #f062cc; color: white"
      >
        <p>Fullscreen</p>
      </div>
    </div>
  </fragment>
</template>

<script>
import Scale from '@/components/pos/Scale'
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  props: ['addOrder', 'scale', 'products', 'addScaleValue'],
  components: { Scale },
  data() {
    return {
      shops: [],
      selectedShop: null,
      showOptions: false,
      selectedShopString: 'No shop selected',
    }
  },
  created() {
    this.selectedShop = this.$store.state.selectedShop
    this.getShops()
  },
  methods: {
    goFullscreen() {
      var elem = document.documentElement
      var fullscreen = document.fullscreenElement
      fullscreen == null ? elem.requestFullscreen() : document.exitFullscreen()
    },
    changeShop(shopId) {
      this.$store.commit('SET_SELECTED_SHOP', shopId)
      this.selectedShop = shopId
      this.showOptions = !this.showOptions

      this.$store.commit('UPDATE_POS_PRODUCT', {
        id: this.$store.state.selectedUser.id,
        products: [],
      })
    },
    getShops() {
      axios.get('storage/getCompanyShops').then((response) => {
        this.shops = response.data
      })
    },
    getShopName(id) {
      if (id == null) {
        return 'Select a shop'
      } else {
        let obj = this.shops.find((x) => x.id == id)
        return obj.locationName
      }
    },
  },
}
</script>

<style scoped lang="scss">
.shopsList {
  position: absolute;
  width: 100%;
  bottom: calc(100% + 10px);
}
.shopsList div {
  background: $accent;
  color: white;
  padding: 10px;
  margin-top: 5px;
  border-radius: 5px;
  cursor: pointer;
}
.selectedShop {
  cursor: pointer;
  padding: 15px;
}
.optionsOrderTop .boxDesign {
  cursor: pointer;
}

.shopsList div:hover {
  background: #0f4c6e;
  color: rgb(255, 255, 255);
}
.optionsOrder {
  grid-column-start: 5;
  grid-column-end: 10;
  grid-row-start: 9;
  grid-row-end: 10;
  display: grid;
  grid-auto-rows: 80px;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 10px;
  row-gap: 0.5em;
}
.optionsOrderTop select {
  padding: 10px;
  width: 80%;
  background: $accent;
  color: white;
  border: none;
  border-radius: 5px;
}
.optionsOrderTop {
  position: relative;
  grid-column-start: 5;
  grid-column-end: 10;
  grid-row-start: 8;
  grid-row-end: 9;
  display: grid;
  grid-auto-rows: 80px;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 10px;
  row-gap: 0.5em;
}
.optionsOrder div:hover {
  color: white;
  background: $accent;
  cursor: pointer;
}
</style>